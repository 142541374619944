import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Table } from "../Table";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25Util } from "../../../util/s25-util";

@TypeManagerDecorator("s25-ng-table-list")
@Component({
    selector: "s25-ng-table-list",
    template: `
        <ul *ngIf="!ordered && items?.length" [class.hasDecoration]="hasDecoration">
            <ng-container *ngTemplateOutlet="list"></ng-container>
        </ul>
        <ol *ngIf="ordered && items?.length" [class.hasDecoration]="hasDecoration">
            <ng-container *ngTemplateOutlet="list"></ng-container>
        </ol>

        <ng-template #list>
            <li *ngFor="let cell of items | slice: 0 : visibleRows">
                <s25-ng-table-cell [defaults]="cell" [row]="row"></s25-ng-table-cell>
            </li>
        </ng-template>

        <div *ngIf="hasPagination && pageSize < items.length" class="button-group">
            <button
                [disabled]="visibleRows >= items.length"
                (click)="onShowMore()"
                class="aw-button aw-button--outline"
            >
                More
            </button>
            <button [disabled]="visibleRows <= pageSize" (click)="onShowLess()" class="aw-button aw-button--outline">
                Less
            </button>
        </div>
    `,
    styles: `
        ul {
            margin: auto;
            text-align: left;
            padding-inline-start: 1.1em;
        }

        ul:not(.hasDecoration) {
            list-style-type: none;
            padding-inline-start: 0;
        }

        ::ng-deep s25-ng-table-list li > * {
            display: block;
        }

        .button-group {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5em;
            margin: 0.5em 0;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericTableListComponent implements OnInit {
    @Input() ordered = false;
    @Input() items: Table.Cell[];
    @Input() row: Table.Row;
    @Input() visibleRows = 5;
    @Input() pageSize: number;
    @Input() hasDecoration = true;
    @Input() hasPagination = true;
    @Input() flex: number = 1; // If there's just this many more items, show them instead of hiding behind "more" button

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        if (S25Util.isUndefined(this.pageSize)) this.pageSize = this.visibleRows;
        if (!this.hasPagination) this.visibleRows = this.items.length;
        if (this.items.length - this.visibleRows === this.flex) {
            this.visibleRows += this.flex;
            this.hasPagination = false;
        }
    }

    onShowMore() {
        this.visibleRows += this.pageSize;
        if (this.items.length - this.visibleRows === this.flex) this.visibleRows += this.flex;
        this.changeDetector.detectChanges();
    }

    onShowLess() {
        this.visibleRows -= this.pageSize;
        if (this.visibleRows === this.pageSize + this.flex) this.visibleRows -= this.flex;
        this.visibleRows = Math.max(this.visibleRows, this.pageSize);
        this.changeDetector.detectChanges();
    }
}
