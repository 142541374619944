import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { Table } from "../Table";
import { S25ButtonComponent } from "../../../standalone/s25.button.component";

@Component({
    template: `
        <s25-ng-button
            [type]="type"
            [disabled]="disabled"
            [isLoading]="isLoading"
            (click)="click.emit(); $event?.stopPropagation()"
            (keydown.enter)="$event?.stopPropagation()"
        >
            {{ label }}
        </s25-ng-button>
    `,
})
export class GenericTableButtonComponent {
    @Input() label: string;
    @Input() type?: S25ButtonComponent["type"] = "outline";
    @Input() disabled?: boolean;
    @Output() click = new EventEmitter<void>();

    _isLoading = false;
    get isLoading() {
        return this._isLoading;
    }
    set isLoading(val: boolean) {
        this._isLoading = val;
        this.changeDetector.detectChanges();
    }

    constructor(private changeDetector: ChangeDetectorRef) {}

    public static Column(
        label: string,
        onClick?: (row: Table.Row, instance?: GenericTableButtonComponent) => void,
        type: S25ButtonComponent["type"] = "outline",
        width: Table.Column["width"] = "min-content",
        disabled?: boolean,
        customHeader?: string,
    ): Table.Column {
        return {
            id: label.toLowerCase().replace(" ", "_"),
            header: customHeader ?? label,
            width: width,
            align: "center",
            sortable: false,
            content: {
                component: GenericTableButtonComponent,
                inputs: {
                    label,
                    type,
                    disabled,
                },
                outputs: {
                    click: (_, row, instance) => onClick?.(row, instance),
                },
            },
        };
    }
}
