import { Component, HostListener } from "@angular/core";
import { S25EditableDateComponent } from "../../s25-editable/s25-editable-date/s25.editable.date.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-table-editable-date")
@Component({
    selector: "s25-ng-table-editable-date",
    template: `
        <div
            (click)="clickInside($event)"
            (keydown.enter)="clickInside($event)"
            [tabindex]="readOnly ? -1 : 0"
            [attr.role]="readOnly ? '' : 'button'"
            [attr.aria-label]="'Edit date'"
        >
            <s25-ng-icon *ngIf="!readOnly" [type]="'edit'"></s25-ng-icon>
            <span *ngIf="!isEditing || readOnly">{{ val | datePipe: dateFormat }}</span>
            <s25-ng-editable-date
                *ngIf="isEditing && !readOnly"
                [(val)]="val"
                [readOnly]="readOnly"
                [dateFormat]="dateFormat"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [popoverOnBody]="popoverOnBody"
                [inputId]="inputId"
                [min]="min"
                [max]="max"
                [disabled]="disabled"
                [allowEmpty]="allowEmpty"
                [hasCommit]="hasCommit"
                [hasCancelButton]="hasCancelButton"
                [hasCommitButton]="hasCommitButton"
                [alwaysEditing]="alwaysEditing"
                [cancelButtonText]="cancelButtonText"
                [commitButtonText]="commitButtonText"
                [customValidation]="customValidation"
                [fieldID]="fieldID"
                (valChange)="valChange.emit($event)"
                [hasIcon]="false"
            ></s25-ng-editable-date>
        </div>
    `,
    styles: `
        s25-ng-icon {
            float: right;
        }

        ::ng-deep s25-ng-table-editable-date s25-ng-icon svg {
            height: 1em !important;
        }
    `,
})
export class TableEditableDateComponent extends S25EditableDateComponent {
    isEditing = false;

    clickInside(event: Event & { component?: TableEditableDateComponent }) {
        event.component = this;
        this.isEditing = true;
    }

    @HostListener("window:click", ["$event"])
    clickOutside(event: Event & { component: TableEditableDateComponent }) {
        if (event.component === this) return; // Only trigger when target is not element
        this.isEditing = false;
    }
}
