import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { StandardSchedule, StandardScheduleService } from "../../services/standard.schedule.service";
import { DropDownItem } from "../../pojo/DropDownItem";
import { S25Const } from "../../util/s25-const";
import { FlsService } from "../../services/fls.service";
import { TelemetryService } from "../../services/telemetry.service";
import { S25StandardSchedulePatterns } from "./s25.standard.schedule.patterns";

@TypeManagerDecorator("s25-ng-standard-schedule-list")
@Component({
    selector: "s25-ng-standard-schedule-list",
    template: `
        <div *ngIf="isInit" class="top">
            <h4>Standard Schedule</h4>
            <div>
                <a
                    href="{{ x25Url }}"
                    target="_blank"
                    (click)="onCreateEditStdSch()"
                    class="ngFloatRight"
                    *ngIf="flsM25Export !== 'N'"
                >
                    <button class="aw-button aw-button--outline c-margin-bottom--half">
                        Create and Edit Standard Schedules
                    </button>
                </a>
                <div class="dropdown">
                    <s25-generic-dropdown
                        [items]="standardSchedules"
                        [placeholder]="'Select a Standard Schedule'"
                        [searchEnabled]="true"
                        [(chosen)]="choosenStandardSchedule"
                        (chosenChange)="chooseStandardSchedule($event)"
                    ></s25-generic-dropdown>
                </div>
            </div>
            <br />
            <s25-ng-standard-schedule-patterns *ngIf="choosenStandardSchedule" [items]="[choosenStandardSchedule]">
            </s25-ng-standard-schedule-patterns>
        </div>
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .header > button {
            margin-bottom: 1em;
        }

        .dropdown {
            max-width: 520px;
        }
    `,
})
export class S25StandardScheduleListComponent implements OnInit {
    isInit = false;
    standardSchedule: StandardSchedule;
    standardSchedules: any = [];
    choosenStandardSchedule: DropDownItem;
    x25Url: String = "";
    flsM25Export: string;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        this.x25Url =
            "https://25live.collegenet.com/x25/" + S25Const.instanceId + "#/Projects/Settings/Standard-Schedules/";
        this.standardSchedules = await StandardScheduleService.getStandardScheduleList(4);
        this.standardSchedules = S25Util.array.forceArray(this.standardSchedules);
        this.standardSchedules = this.standardSchedules.map((item: any) => {
            return { itemId: item.id, itemName: item.name };
        });
        const getFls = await FlsService.getFls();
        this.flsM25Export = getFls.M25_EXPORT;
        this.isInit = true;
        this.cd.detectChanges();
    }

    chooseStandardSchedule(e: any) {
        TelemetryService.send("StandardSchedules", "Select");
        this.cd.detectChanges();
    }

    error(error: any) {
        S25Util.showError(error);
    }

    onCreateEditStdSch = () => {
        TelemetryService.send("StandardSchedules", "X25");
    };
}
