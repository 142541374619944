import { Component, Input } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-generic-table-text-preview")
@Component({
    selector: "s25-ng-generic-table-render-html",
    template: `
        <div *ngIf="!isHtml" class="preview-snippet">{{ text }}</div>
        <div *ngIf="isHtml" class="preview-snippet" [innerHTML]="text"></div>
    `,
})
export class GenericTableRenderHTMLComponent {
    @Input() text: string;
    @Input() isHtml: boolean;
}
